.flex {
  display: flex;
}

.inputContainer {
  margin-left: calc(2 * var(--base-spacing));
}

.rowContainer {
  margin-top: calc(2 * var(--base-spacing));
}

.spacing {
  height: calc(2 * var(--base-spacing));
}
