.container {
  display: flex;
  align-items: center;
  column-gap: var(--base-spacing);
}

.fullWidth {
  width: 100%;
}

.label {
  font-size: 1.875rem;
  color: var(--accent);
  cursor: pointer;
}

.hidden {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: grid;
  place-content: center;
  color: var(--accent);
  cursor: pointer;
}

.hidden:checked {
  color: var(--primary-color);
}

.hidden::before {
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--primary-color);
}

.hidden:checked::before {
  transform: scale(1);
}
