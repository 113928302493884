.addForm {
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2fr) minmax(
      0,
      1fr
    );
  column-gap: var(--base-spacing);
  margin-top: calc(3 * var(--base-spacing));
  margin-bottom: calc(3 * var(--base-spacing));
}

.addContainer {
  display: flex;
  align-items: flex-end;
}

.header {
  margin: calc(4 * var(--base-spacing)) 0;
  font-size: 2rem;
}

.subSection {
  margin: calc(4 * var(--base-spacing)) 0;
  font-size: 1.5rem;
}

.removeButton {
  cursor: pointer;
  font-weight: 500;
  color: var(--primary-color);
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 1.5rem;
}
.removeButton:disabled {
  opacity: 0.5;
}

.gridContainer {
  display: grid;
  grid-template-columns: minmax(100px, 2fr) minmax(100px, 2fr) minmax(
      100px,
      1fr
    );
}

.gridContainer > div {
  padding: 0 8px;
}

.rightAligned {
  text-align: right;
}

.record {
  margin-top: var(--base-spacing);
}
