.container {
  background-color: var(--accent-background);
  padding: var(--base-spacing) calc(2 * var(--base-spacing))
    calc(0.5 * var(--base-spacing));
  width: 450px;
  border-radius: 8px;
}

.fullWidth {
  width: 100%;
  max-width: 100%;
}

.label {
  color: var(--accent);
  font-size: 1.5rem;
  display: block;
  margin-bottom: calc(1.5 * var(--base-spacing));
}
.inputWrapper {
  position: relative;
}
