.text {
  color: var(--primary-color);
  font-size: 1.875rem;
  display: block;
  cursor: pointer;
}

.previewImage {
  width: 282px;
  height: 282px;
  background-color: white;
  display: block;
  margin-top: calc(1.33 * var(--base-spacing));
  border-radius: 12px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
}
