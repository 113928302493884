.container {
  color: var(--primary-color);
}

.container svg {
  vertical-align: baseline;
}

.active {
  border-bottom: solid 3px var(--primary-color);
}

.text {
  margin-left: calc(0.33 * var(--base-spacing));
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 500;
}
