.link {
  font-size: 1.875rem;
  color: var(--primary-color);
  font-weight: 500;
  text-decoration: none;
}

.link:not(:last-child)::after {
  content: "/";
  padding-left: calc(0.5 * var(--base-spacing));
  padding-right: calc(0.5 * var(--base-spacing));
}
