.topSection {
  display: flex;
  column-gap: calc(2.5 * var(--base-spacing));
  margin-top: calc(1.625 * var(--base-spacing));
}

.customerDetail {
  background-color: var(--background-color);
  font-size: 1.5rem;
  padding: calc(1.625 * var(--base-spacing));
  border-radius: 0.625rem;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
  flex-grow: 1;
}

.customerDetail > div:not(:first-child) {
  margin-top: calc(1.125 * var(--base-spacing));
}

.customerDetail > div > span:first-child {
  color: var(--accent);
  display: inline-block;
  margin-right: calc(0.7 * var(--base-spacing));
}

.topSectionButton {
  color: var(--primary-color);
  cursor: pointer;
}

.limits {
  display: flex;
  flex-direction: column;
}

.limits > div:last-child {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 560px;
}

.modalHeader > *:nth-child(2) {
  cursor: pointer;
}

.modalHeading {
  font-weight: 500;
  color: var(--primary-color);
  font-size: 1.875rem;
}

.modalContent {
  margin-top: calc(7 * var(--base-spacing));
  padding-left: calc(3 * var(--base-spacing));
  padding-right: calc(3 * var(--base-spacing));
}

.customerInfo {
  font-size: 1.5rem;
}

.customerInfo > div:not(:first-child) {
  margin-top: calc(1.125 * var(--base-spacing));
}

.customerInfo > div > span:first-child {
  color: var(--accent);
  display: inline-block;
  margin-right: calc(0.7 * var(--base-spacing));
}

.limitsContainer {
  margin-top: calc(10.33 * var(--base-spacing));
}

.modalButtonContainer {
  text-align: center;
  margin-top: calc(4.125 * var(--base-spacing));
}

.manualPayment {
  margin-top: calc(6 * var(--base-spacing));
}

.customerForm > * {
  margin-top: calc(2 * var(--base-spacing));
}
