.container {
  padding-left: calc(2 * var(--base-spacing));
}

.heading {
  font-size: 2.8125rem;
}

.flex {
  display: flex;
}

.inputContainer {
  margin-left: calc(2 * var(--base-spacing));
}

.form {
  display: flex;
  justify-content: space-between;
}

.buttonContainer {
  align-self: flex-end;
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: calc(1.5 * var(--base-spacing));
  margin-bottom: calc(4.5 * var(--base-spacing));
}

.spacing {
  height: calc(2 * var(--base-spacing));
}
