.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: calc(1.5 * var(--base-spacing));
  margin-bottom: calc(4.5 * var(--base-spacing));
}

.heading {
  font-size: 2.8125rem;
}

.tab {
  width: 50%;
  padding: calc(3 * var(--base-spacing)) 0;
  text-align: center;
  color: var(--accent);
  background-color: var(--accent-background);
  font-weight: 500;
  font-size: 2.625rem;
  cursor: pointer;
}

.activeTab {
  background-color: white;
}

.noClick {
  cursor: not-allowed;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

.tabsContainer {
  display: flex;
  margin-bottom: calc(3 * var(--base-spacing));
}

.topSection {
  margin-bottom: calc(3 * var(--base-spacing));
  display: flex;
}

.topSection > div {
  flex-grow: 1;
}

.daysLabel {
  color: var(--accent);
  font-size: 1.25rem;
  font-weight: 500;
}

.branchInputsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: calc(2 * var(--base-spacing));
  margin-top: calc(3 * var(--base-spacing));
  margin-bottom: calc(3 * var(--base-spacing));
}

.branchName {
  font-weight: 500;
  font-size: 2.625rem;
  color: var(--accent);
}

.table {
  border-collapse: collapse;
  position: relative;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.headerRow > td {
  font-weight: bold;
}

.pricesContainer {
  margin-bottom: calc(2 * var(--base-spacing));
  overflow-x: auto;
  padding-bottom: calc(3.33 * var(--base-spacing));
}

.table td,
.table th {
  padding: 10px var(--base-spacing);
}

.table td:not(.headerRow td) {
  padding: 10px 0;
}

.table input {
  border: none;
  outline: none;
  background-color: transparent;
  width: 75px;
  text-align: center;
}

.table > thead input {
  width: 130px;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.addColumn {
  position: absolute;
  top: 0;
  height: 100%;
  right: -40px;
  width: 40px;
  writing-mode: vertical-lr;
  text-align: center;
  padding: 8px;
  cursor: pointer;
}

.addColumn:hover,
.addRow:hover {
  background-color: var(--accent-background);
}

.addRow {
  position: absolute;
  bottom: -40px;
  width: 100%;
  left: 0;
  height: 40px;
  text-align: center;
  padding: 8px;
  cursor: pointer;
}

.taperedHeading {
  font-size: 1.875rem;
  font-weight: 500;
  margin-bottom: calc(1.5 * var(--base-spacing));
}

.nextContainer {
  display: flex;
  justify-content: center;
  margin-top: calc(5 * var(--base-spacing));
  margin-bottom: calc(3 * var(--base-spacing));
}

.padder {
  padding-bottom: 450px;
  display: flex;
  justify-content: flex-end;
  margin-top: calc(2 * var(--base-spacing));
}

.modalContainer {
  width: 40vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
}

.modalPrompt {
  flex-grow: 1;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalActionContainer {
  display: flex;
  justify-content: flex-end;
  column-gap: calc(3 * var(--base-spacing));
}
