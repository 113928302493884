.container {
  display: grid;
  gap: calc(2 * var(--base-spacing));
  grid-template-columns: 1fr 1fr 1fr;
}

.spacer {
  height: calc(3.5 * var(--base-spacing));
}

.heading {
  margin-top: calc(4 * var(--base-spacing));
  font-size: 2.8125rem;
  margin-bottom: calc(7 * var(--base-spacing));
}

.imagesContainer {
  display: flex;
  gap: calc(2 * var(--base-spacing));
  margin-top: calc(2 * var(--base-spacing));
}

.accessoriesButtonContainer {
  display: flex;
  align-items: flex-end;
}
