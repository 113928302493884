.row {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: calc(2.5 * var(--base-spacing));
  grid-row-gap: calc(2 * var(--base-spacing));
  margin-top: calc(2.5 * var(--base-spacing));
  margin-bottom: calc(2.5 * var(--base-spacing));
}

.success {
  color: var(--success);
}

.error {
  color: var(--error);
}

.info {
  color: var(--info);
}

.list > div:not(:first-child) {
  margin-top: calc(3 * var(--base-spacing));
}

.bottomSection {
  background-color: var(--accent-background);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: calc(2.5 * var(--base-spacing)) calc(2 * var(--base-spacing));
  margin-top: calc(2 * var(--base-spacing));
  display: flex;
  justify-content: space-between;
}

.paymentsHeader {
  font-size: 1.875rem;
  margin-bottom: calc(2.5 * var(--base-spacing));
}

.paymentsTable td,
.paymentsTable th {
  padding: calc(0.83 * var(--base-spacing)) calc(1.67 * var(--base-spacing));
  font-size: 1.25rem;
}

.paymentsTable tr > td:first-child,
.paymentsTable tr > th:first-child {
  padding-left: 0;
}

.status {
  text-transform: capitalize;
}

.paymentDivision {
  font-size: 1.875rem !important;
  color: var(--accent);
}

.paymentValue {
  font-size: 1.875rem !important;
}

.total {
  font-size: 2.5rem;
  color: var(--accent);
  border-bottom: solid 3px black;
  padding-top: calc(2.5 * var(--base-spacing));
  padding-bottom: calc(1.67 * var(--base-spacing));
}

.totalValue {
  font-size: 2.5rem;
  border-bottom: solid 3px black;
  padding-top: calc(2.5 * var(--base-spacing));
  padding-bottom: calc(1.67 * var(--base-spacing));
}

.large {
  font-size: 2.5rem;
}

.banner {
  display: flex;
  padding: calc(1.67 * var(--base-spacing)) calc(3 * var(--base-spacing));
  background-color: #f8f7e9;
  margin-top: calc(2.5 * var(--base-spacing));
  margin-left: calc(-3 * var(--base-spacing));
  margin-right: calc(-3 * var(--base-spacing));
  align-items: center;
  justify-content: space-between;
}

.bannerText {
  font-size: 2.5rem;
  font-weight: 500;
}

.actionContainer {
  display: flex;
  margin-top: calc(2.5 * var(--base-spacing));
  justify-content: flex-end;
  margin-bottom: calc(5 * var(--base-spacing));
}

.dropdownContainer {
  position: relative;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.button {
  border: solid 1px var(--primary-color);
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  padding: calc(1.68 * var(--base-spacing)) calc(2 * var(--base-spacing));
  border-radius: 10px 0 0 10px;
  font-size: 1.5rem;
}

.button:disabled {
  opacity: 0.5;
}

.buttonOpen {
  border-radius: 10px 0 0 0;
}

.icon {
  padding: calc(2.1 * var(--base-spacing)) calc(1.5 * var(--base-spacing));
  border: solid 1px var(--primary-color);
  border-left: none;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
}

.icon[data-disabled="true"] {
  opacity: 0.5;
}

.iconInverted {
  background-color: var(--background-alt);
  border-radius: 0 10px 0 0;
}

.invert {
  transform: rotate(180deg);
  transition: all 300ms ease-in-out;
}

.regular {
  transform: rotate(0deg);
  transition: all 300ms ease-in-out;
}

.otherOption {
  width: 100%;
  position: absolute;
  top: 76px;
  left: 0;
  text-align: center;
  color: var(--primary-color);
  font-size: 1.5rem;
  padding: calc(2 * var(--base-spacing)) 0;
  cursor: pointer;
  border: solid 1px var(--primary-color);
  border-top: none;
  background-color: var(--background-alt);
  border-radius: 0 0 10px 10px;
  visibility: hidden;
  transition: all 100ms ease-in-out;
}

.otherOptionOpen {
  visibility: visible;
  transition: all 100ms ease-in-out;
}
