.container {
  background-color: var(--accent-background);
  padding: var(--base-spacing) calc(2 * var(--base-spacing))
    calc(0.5 * var(--base-spacing));
  width: 450px;
  border-radius: 8px;
  position: relative;
}

.fullWidth {
  width: 100%;
  max-width: 100%;
}

.label {
  color: var(--accent);
  font-size: 1.5rem;
  display: block;
  margin-bottom: calc(1.5 * var(--base-spacing));
}

.selectContainer {
  width: 100%;
}

.selectButton {
  border: none;
  background-color: white;
  padding: calc(0.83 * var(--base-spacing));
  color: var(--primary-color);
  border-radius: 8px;
  font-size: 2.25rem;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectedValue {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectDropdown {
  position: absolute;
  left: 0;
  list-style: none;
  width: 100%;
  box-shadow: 10px 10px 19px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: calc(0.65 * var(--base-spacing));
  max-height: 400px;
  overflow-y: auto;
  transition: 0.3s ease;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  pointer-events: none;
}

.selectDropdown:focus-within {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

.selectDropdown li {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-bottom: solid 1px var(--border-gray);
  padding: 0;
}

.selectDropdown li label {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  padding: var(--base-spacing) calc(2.83 * var(--base-spacing));
  font-size: 1.75rem;
  color: var(--accent);
}

.selectDropdown::-webkit-scrollbar {
  width: 6px;
}
.selectDropdown::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 25px;
}

.selectDropdown::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 25px;
}

.selectDropdown li:hover,
.selectDropdown input:checked ~ label {
  background-color: var(--accent-background);
}

.selectDropdown input[type="radio"] {
  position: absolute;
  left: calc(4 * var(--base-spacing));
  opacity: 0;
  visibility: visible;
}

.selectContainer.active .selectDropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.selectContainer.active .arrow {
  transform: rotate(180deg);
}
