.pointer-all {
  pointer-events: all;
}

.block {
  display: block;
}

.w-4 {
  width: 1rem;
}

.h-4 {
  height: 1rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.-translate-50 {
  transform: translate(-50%, -50%);
}

.bg-error {
  background-color: #c72439;
}

.bg-success {
  background-color: #3f8827;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex;
}

.w-6 {
  width: 1.5rem;
}

.bg-transparent {
  background-color: transparent;
}

.outline-none {
  outline-width: 0;
}

.focus\:outline-none:focus {
  outline-width: 0;
}

.\!mt-5 {
  margin-top: 1.25rem !important;
}

.\!mr-5 {
  margin-right: 1.25rem !important;
}

.drop-shadow-pova-flashing {
  filter: drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.25));
}

.bg-white {
  background-color: white;
}

.rounded-pova-lg {
  border-radius: 10px;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-2\.5 {
  padding-left: 10px;
  padding-right: 10px;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.text-xs {
  font-size: 0.75rem;
}

.text-center {
  text-align: center;
}

.font-bold {
  font-weight: bold;
}

.text-xl {
  font-size: 1.25rem;
}

.\!left-0 {
  left: 0 !important;
}

.-top-0\.5 {
  top: -2px;
}

.top-0\.5 {
  top: 2px;
}

.invisible {
  visibility: hidden;
}

.text-disabled {
  color: #d7d7d7;
}

.flex-col {
  flex-direction: column;
}

.text-\[10px\] {
  font-size: 10px;
}

.font-light {
  font-weight: 300;
}

.mt-1 {
  margin-top: 0.25rem;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.border-0 {
  border-width: 0;
}

.\!m-0 {
  margin: 0;
}

.bg-surface {
  background-color: #eeeeee;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.rounded-3xl {
  border-radius: 1rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-primary {
  color: black;
}

.gap-x-4 {
  column-gap: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.p-1 {
  padding: 0.25rem;
}

.font-semibold {
  font-weight: 600;
}

.p-2 {
  padding: 0.5rem;
}

.grow {
  flex-grow: 1;
}

.border {
  border-width: 1px;
}

.border-primary {
  border-color: black;
}

.relative {
  position: relative;
}

.crushFoldButton {
  position: absolute;
  width: 111px;
  left: -115px;
  top: 0;
  background: black;
  border-radius: 1.5rem;
  padding-block: 0.75rem;
  cursor: pointer;
  padding-inline: 0.5rem;
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
}

.crushFoldButton:focus {
  outline: none;
}

.button2Base {
  position: absolute;
  width: 111px;
  left: -115px;
  border-radius: 1.5rem;
  padding-block: 0.75rem;
  cursor: pointer;
  padding-inline: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  border-width: 0;
}

.button2Assigned {
  background-color: black;
  color: white;
  top: 3.5rem;
}

.button2NotAssigned {
  background-color: #eeeeee;
  top: 3.5rem;
}

.button2Only {
  top: 0;
  background-color: #eeeeee;
}

.button2Base:focus {
  outline: none;
}

.button2Base:disabled {
  color: #d7d7d7;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none;
}

.actionContainer {
  position: absolute;
  border-radius: 1.25rem;
  padding: 0.25rem;
  cursor: pointer;
}

.actionsWrapper {
  display: flex;
  row-gap: 0.25rem;
  align-items: center;
  font-size: 1.25rem;
}

.taperedVertex {
  transform: translate(142px, -174px);
}

.taperedEdge {
  width: 3px;
  height: 196px;
  background-color: rgba(81, 81, 81, 0.3);
  transform: translate(5px, -233px) rotate(45deg);
  position: absolute;
  transform-origin: bottom;
}

.z-10 {
  z-index: 10;
}

.shadow-pova-sm {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.text-on-surface-variant {
  color: var(--accent);
}
