.container {
  display: flex;
  height: 75px;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  padding-left: calc(2 * var(--base-spacing));
  padding-right: calc(2 * var(--base-spacing));
  background-color: white;
  width: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}

.profileContainer {
  display: flex;
  align-items: center;
}

.leftMargin {
  margin-left: var(--base-spacing);
}

.leftMarginLarge {
  margin-left: calc(3 * var(--base-spacing));
}

.profile {
  color: var(--secondary-color);
  font-size: 1.125rem;
}

.designation {
  font-weight: 300;
}
