.container > div:not(:first-child) {
  margin-top: calc(4 * var(--base-spacing));
}

.actionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(2 * var(--base-spacing));
}

.addVariantContainer {
  transform: translateX(-50%);
}

.modalContainer {
  width: 40vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
}

.modalPrompt {
  flex-grow: 1;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalActionContainer {
  display: flex;
  justify-content: flex-end;
  column-gap: calc(3 * var(--base-spacing));
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(4.5 * var(--base-spacing));
  margin-top: calc(1.5 * var(--base-spacing));
}

.heading {
  font-size: 2.8125rem;
}

.tabsContainer {
  display: flex;
  margin-bottom: calc(3 * var(--base-spacing));
}

.tab {
  width: 50%;
  padding: calc(3 * var(--base-spacing)) 0;
  text-align: center;
  color: var(--accent);
  background-color: var(--accent-background);
  font-weight: 500;
  font-size: 2.625rem;
  cursor: pointer;
}

.activeTab {
  background-color: white;
}

.noClick {
  cursor: not-allowed;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}
