.container {
  width: 100%;
  padding: calc(1.25 * var(--base-spacing)) calc(2.5 * var(--base-spacing));
  box-shadow: 10px 10px 19px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  margin-bottom: calc(3 * var(--base-spacing));
}

.mainText {
  color: var(--accent);
  font-size: 2.75rem;
}

.inputContainer {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: calc(2 * var(--base-spacing)) var(--base-spacing);
  margin-top: calc(3 * var(--base-spacing));
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--primary-color);
  cursor: pointer;
}

.portalsContainer {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: calc(6 * var(--base-spacing));
  margin-top: calc(2 * var(--base-spacing));
}

.radioLabel {
  font-size: 1.25rem;
}

.spacer {
  margin-top: calc(3 * var(--base-spacing));
}

.radioContainer {
  padding: var(--base-spacing);
  background-color: var(--accent-background);
  padding-top: calc(2 * var(--base-spacing));
}

.optionsContainer {
  display: grid;
  grid-column-gap: var(--base-spacing);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
