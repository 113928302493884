.container {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.leftSection {
  width: 43%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.rightSection {
  width: 57%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
}

.leftCardSection {
  width: 60%;
  height: 75%;
  filter: drop-shadow(0px 10px 19px rgba(0, 0, 0, 0.16));
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.logoContainer {
  text-align: center;
}

.name {
  margin-top: calc(2.5 * var(--base-spacing));
}

.rightCardSection {
  width: 68.5%;
  height: 75%;
  filter: drop-shadow(10px 10px 19px rgba(0, 0, 0, 0.16));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.heading {
  font-size: 2.25rem;
  font-weight: 500;
}

.form {
  margin-top: calc(3.75 * var(--base-spacing));
  width: 400px;
}

.label {
  font-size: 1.5rem;
  margin-bottom: calc(2 * var(--base-spacing));
  display: block;
  margin-top: calc(2.5 * var(--base-spacing));
}

.actionContainer {
  margin-top: calc(3.75 * var(--base-spacing));
}

.input {
  display: block;
  width: 100%;
  border: none;
  background-color: var(--background-color);
  padding: calc(2 * var(--base-spacing));
  color: var(--secondary-color);
  font-size: 1.5rem;
  font-weight: 300;
  border-radius: 0.75rem;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input:focus {
  outline: none;
}

.hidden {
  visibility: hidden;
}
