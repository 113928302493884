.input {
  display: block;
  width: 100%;
  border: none;
  background-color: white;
  padding: calc(0.83 * var(--base-spacing));
  color: var(--primary-color);
  font-size: 2.25rem;
  border-radius: 8px;
}

.smallFont {
  font-size: 1.25rem;
  padding: calc(1.75 * var(--base-spacing)) calc(0.83 * var(--base-spacing));
}

.input:disabled {
  cursor: not-allowed;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.multiline {
  font-size: 1.5rem;
}

.input:focus {
  outline: none;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--base-spacing);
}

.endIcon {
  padding-right: calc(3 * var(--base-spacing));
}
