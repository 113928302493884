.container {
  padding: calc(0.5 * var(--base-spacing)) calc(2 * var(--base-spacing))
    calc(1.5 * var(--base-spacing)) 0;
  margin-top: calc(1.5 * var(--base-spacing));
}

.heading {
  font-size: 2rem;
  font-weight: 500;
  color: var(--accent);
}

.label {
  font-size: 1.5rem;
}

.row {
  display: grid;
  grid-template-columns: 26.5% 16% 16% 37.5%;
  grid-column-gap: calc(2 * var(--base-spacing));
  margin-top: calc(4.5 * var(--base-spacing));
}

.row > * {
  align-self: flex-start;
}

.colourTypesRow {
  display: grid;
  grid-template-columns: 33% 67%;
  grid-column-gap: calc(2 * var(--base-spacing));
  margin-top: calc(3 * var(--base-spacing));
}

.coloursContainer {
  display: flex;
  column-gap: calc(2 * var(--base-spacing));
  row-gap: calc(0.5 * var(--base-spacing));
  flex-wrap: wrap;
}

.colourContainer {
  display: flex;
  justify-content: space-between;
  column-gap: var(--base-spacing);
  min-width: 230px;
  border-radius: 50px;
  background-color: var(--accent-background);
  height: 35px;
  align-items: center;
  padding-right: var(--base-spacing);
  padding-left: var(--base-spacing);
}

.colour {
  display: flex;
  align-items: center;
  gap: calc(0.5 * var(--base-spacing));
}

.colourName {
  font-size: 1.25rem;
}
