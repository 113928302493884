.nextContainer {
  display: flex;
  justify-content: center;
  margin-top: calc(4.5 * calc(--base-spacing));
  margin-bottom: calc(2 * var(--base-spacing));
}

.branchInputsContainer {
  display: flex;
  gap: calc(2 * var(--base-spacing));
  margin-top: calc(3.33 * var(--base-spacing));
}

.branchName {
  font-weight: 500;
  font-size: 2.625rem;
  color: var(--accent);
}

.branchContainer {
  padding: calc(1.33 * var(--base-spacing)) 0 calc(1.33 * var(--base-spacing))
    calc(2 * var(--base-spacing));
  margin-top: calc(2 * var(--base-spacing));
}

.branchTopSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.defaultBranch {
  font-size: 1.875rem;
  color: var(--accent);
  font-weight: 500;
  cursor: pointer;
}

.daysLabel {
  color: var(--accent);
  font-size: 1.25rem;
  font-weight: 500;
}

.ctaContainer {
  display: flex;
  column-gap: calc(2 * var(--base-spacing));
}

.switch {
  position: relative;
  display: inline-block;
  width: 9rem;
  height: 3rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-color);
  border-radius: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: var(--base-spacing);
  padding-left: var(--base-spacing);
  font-size: 0.75rem;
}

.slider:before {
  position: absolute;
  content: "";
  height: 2.375rem;
  width: 2.375rem;
  left: 5px;
  bottom: 5px;
  top: 5px;
  background-color: white;
  transition: 0.5s;
  border-radius: 50%;
}

input[type="checkbox"]:checked + .slider:before {
  transform: translateX(6rem);
}

input[type="checkbox"]:checked + .slider {
  justify-content: flex-start;
}
