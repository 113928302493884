.modalContainer {
  width: 40vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
}

.modalPrompt {
  flex-grow: 1;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalActionContainer {
  display: flex;
  justify-content: flex-end;
  column-gap: calc(3 * var(--base-spacing));
}

.button {
  padding: calc(1.66 * var(--base-spacing)) calc(3 * var(--base-spacing));
  border: none;
  font-size: 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  text-decoration: none;
}

.buttonFilled {
  color: white;
  background-color: var(--primary-color);
}

.buttonOutlined {
  color: var(--primary-color);
  background-color: white;
  border: solid 1px var(--primary-color);
}
