.button {
  padding: calc(1.66 * var(--base-spacing)) calc(3 * var(--base-spacing));
  border: none;
  font-size: 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.button:disabled {
  opacity: 0.5;
}

.buttonFilled {
  color: white;
  background-color: var(--primary-color);
}

.buttonOutlined {
  color: var(--primary-color);
  background-color: white;
  border: solid 1px var(--primary-color);
}

.fullWidth {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.rounded {
  border-radius: 0.75rem;
}
