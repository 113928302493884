.input {
  display: block;
  width: 100%;
  border: none;
  background-color: white;
  padding: calc(0.83 * var(--base-spacing));
  color: var(--primary-color);
  font-size: 2.25rem;
  border-radius: 8px;
}
