.topSection {
  display: grid;
  row-gap: calc(2 * var(--base-spacing));
  column-gap: calc(2.5 * var(--base-spacing));
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: calc(6 * var(--base-spacing));
}

.banner {
  display: flex;
  padding: calc(1.67 * var(--base-spacing)) calc(3 * var(--base-spacing));
  background-color: #f8f7e9;
  margin-top: calc(2.5 * var(--base-spacing));
  margin-left: calc(-3 * var(--base-spacing));
  margin-right: calc(-3 * var(--base-spacing));
  align-items: center;
  justify-content: space-between;
}

.bannerText {
  font-size: 2.5rem;
  font-weight: 500;
}

.success {
  color: var(--success);
}

.error {
  color: var(--error);
}

.info {
  color: var(--info);
}

.accent {
  color: var(--accent);
}

.loadingContainer {
  display: flex;
  justify-content: center;
  margin-block: 1rem;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  column-gap: calc(1.5 * var(--base-spacing));
  margin-top: calc(2.75 * var(--base-spacing));
  margin-bottom: calc(2 * var(--base-spacing));
  align-items: center;
}

.priceText {
  color: var(--error);
  font-size: 1.25rem;
}

.bottomSection {
  display: flex;
  justify-content: flex-end;
  margin-top: calc(8.5 * var(--base-spacing));
  margin-bottom: calc(2.5 * var(--base-spacing));
}

.pricingContainer {
  background-color: var(--accent-background);
  padding: calc(2.5 * var(--base-spacing)) calc(2 * var(--base-spacing));
  align-self: flex-start;
}

.pricingContainer > div {
  display: flex;
  align-items: center;
  gap: calc(12 * var(--base-spacing));
  justify-content: space-between;
  font-size: 1.875rem;
  color: var(--accent);
  margin-bottom: calc(3.33 * var(--base-spacing));
}

.pricingContainer > div:nth-child(3) {
  margin-bottom: 0;
  margin-top: var(--base-spacing);
  font-size: 2.5rem;
}

.price {
  text-align: right;
  color: black;
}

.dropdownContainer {
  position: relative;
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.button {
  border: solid 1px var(--primary-color);
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  padding: calc(1.68 * var(--base-spacing)) calc(2 * var(--base-spacing));
  border-radius: 10px 0 0 10px;
  font-size: 1.5rem;
}

.button:disabled {
  opacity: 0.5;
}

.buttonOpen {
  border-radius: 10px 0 0 0;
}

.icon {
  padding: calc(2.1 * var(--base-spacing)) calc(1.5 * var(--base-spacing));
  border: solid 1px var(--primary-color);
  border-left: none;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
}

.icon[data-disabled="true"] {
  opacity: 0.5;
}

.iconInverted {
  background-color: var(--background-alt);
  border-radius: 0 10px 0 0;
}

.invert {
  transform: rotate(180deg);
  transition: all 300ms ease-in-out;
}

.regular {
  transform: rotate(0deg);
  transition: all 300ms ease-in-out;
}

.otherOption {
  width: 100%;
  position: absolute;
  top: 76px;
  left: 0;
  text-align: center;
  color: var(--primary-color);
  font-size: 1.5rem;
  padding: calc(2 * var(--base-spacing)) 0;
  cursor: pointer;
  border: solid 1px var(--primary-color);
  border-top: none;
  background-color: var(--background-alt);
  visibility: hidden;
  transition: all 100ms ease-in-out;
}

.otherOptionOpen {
  visibility: visible;
  transition: all 100ms ease-in-out;
}

.actionContainer {
  display: flex;
  margin-top: calc(2.5 * var(--base-spacing));
  justify-content: flex-end;
  margin-bottom: calc(5 * var(--base-spacing));
}

.secondOption {
  top: 158px;
  border-radius: 0 0 10px 10px;
}
