.coloursContainer {
  display: flex;
  column-gap: calc(0.67 * var(--base-spacing));
  row-gap: calc(0.5 * var(--base-spacing));
  flex-wrap: wrap;
  position: relative;
}

.sizesContainer {
  font-size: 0.94rem;
}

.price {
  font-size: 1.5rem;
}

.previewImage {
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.tableText {
  font-size: 1.25rem;
}

.count {
  position: absolute;
  top: -20px;
  left: -20px;
  background-color: #d7f8bf;
  border-radius: 50%;
  text-align: center;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
}

.sort {
  display: flex;
  justify-content: flex-end;
  column-gap: calc(2 * var(--base-spacing));
}

.dropdownContainer {
  display: flex;
  column-gap: calc(0.5 * var(--base-spacing));
  margin-right: var(--base-spacing);
  position: relative;
  width: 290px;
  background-color: var(--accent-background);
  border-radius: 8px;
  padding: calc(0.75 * var(--base-spacing));
  align-items: center;
}

.dropdownLabel {
  color: var(--accent);
  font-size: 1rem;
  width: 85px;
}

.selectContainer {
  width: 100%;
}

.selectButton {
  border: none;
  padding: calc(0.83 * var(--base-spacing));
  color: var(--accent);
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--accent-background);
}

.selectedValue {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectDropdown {
  position: absolute;
  left: 0;
  list-style: none;
  width: 100%;
  box-shadow: 10px 10px 19px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: calc(0.65 * var(--base-spacing));
  max-height: 400px;
  overflow-y: auto;
  transition: 0.3s ease;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  pointer-events: none;
}

.selectDropdown:focus-within {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

.selectDropdown li {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-bottom: solid 1px var(--border-gray);
  padding: 0;
}

.selectDropdown li label {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  padding: var(--base-spacing) calc(1.5 * var(--base-spacing));
  font-size: 1rem;
  color: var(--accent);
}

.selectDropdown::-webkit-scrollbar {
  width: 6px;
}
.selectDropdown::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 25px;
}

.selectDropdown::-webkit-scrollbar-thumb {
  background: var(--accent);
  border-radius: 25px;
}

.selectDropdown li:hover,
.selectDropdown input:checked ~ label {
  background-color: var(--accent-background);
}

.selectDropdown input[type="radio"] {
  position: absolute;
  left: calc(4 * var(--base-spacing));
  opacity: 0;
  visibility: visible;
}

.selectContainer.active .selectDropdown {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.selectContainer.active .arrow {
  transform: rotate(180deg);
}

.completeCheckbox {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.itemActionsContainer {
  display: flex;
  column-gap: calc(0.67 * var(--base-spacing));
  align-items: center;
}
