.heading {
  font-size: 1.875rem;
  font-weight: 500;
  color: var(--accent);
  padding-left: calc(2 * var(--base-spacing));
  margin-bottom: calc(1.67 * var(--base-spacing));
}

.table {
  border-collapse: collapse;
}

.table th {
  color: var(--accent);
  font-weight: 500;
  background-color: var(--background-color);
  padding: 11px;
}

.table th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.table th:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.table td {
  padding: 5px 11px;
}

.table tr:nth-child(even) > td {
  background-color: var(--background-color);
}

.table tr:nth-child(even) > td:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.table tr:nth-child(even) > td:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.seeAll {
  text-align: center;
  margin-top: calc(2.5 * var(--base-spacing));
  color: var(--primary-color);
}

.seeAll > * {
  cursor: pointer;
}
