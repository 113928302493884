.buttonContainer {
  margin-top: calc(4 * var(--base-spacing));
  margin-left: calc(2 * var(--base-spacing));
}

.editForm {
  margin-top: calc(2.5 * var(--base-spacing));
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: calc(1.75 * var(--base-spacing));
  grid-row-gap: calc(1.75 * var(--base-spacing));
}

.editForm > * {
  align-self: flex-start;
}

.cuttingListContainer {
  background-color: var(--accent-background);
  padding: calc(0.5 * var(--base-spacing)) calc(2 * var(--base-spacing));
  padding-bottom: calc(1.25 * var(--base-spacing));
  min-height: 137px;
}

.cuttingListHeader {
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--accent);
}

.clist {
  margin-top: calc(2.33 * var(--base-spacing));
}

.editButtonsContainer {
  display: flex;
  gap: calc(1.67 * var(--base-spacing));
  margin-top: calc(1.75 * var(--base-spacing));
}

.delete {
  color: var(--primary-color);
  font-size: 1.5rem;
  text-decoration: underline;
  align-self: center;
  cursor: pointer;
}

.action {
  font-size: 1rem;
  padding: var(--base-spacing) calc(2.5 * var(--base-spacing));
  margin-top: calc(2 * var(--base-spacing));
}

.poButton {
  font-size: 0.75rem;
  background-color: var(--primary-color);
  color: white;
  padding: calc(0.25 * var(--base-spacing)) calc(1.75 * var(--base-spacing));
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

.poButton:disabled {
  opacity: 0.5;
}
