.container {
  height: calc(100vh - 75px);
  width: 100%;
}

.contentContainer {
  display: flex;
  margin-top: 75px;
  height: 100%;
}

.childrenContainer {
  background-color: white;
  width: 79%;
  padding: calc(2.66 * var(--base-spacing)) calc(3 * var(--base-spacing)) 0;
  max-height: 100%;
  overflow: auto;
}

.comingSoonContainer {
  display: flex;
  width: 100%;
  height: 600px;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  font-weight: 500;
  color: var(--primary-color);
}
