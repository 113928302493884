.modalContainer {
  width: 560px;
}

.modalHeading {
  font-size: 1.875rem;
  color: var(--primary-color);
  font-weight: 500;
}

.modalItem {
  margin-top: calc(2.5 * var(--base-spacing));
  padding-left: calc(4.5 * var(--base-spacing));
  padding-right: calc(4.5 * var(--base-spacing));
  font-size: 2.25rem;
  font-weight: 500;
  margin-bottom: calc(4 * var(--base-spacing));
}

.modalTopRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalTable {
  width: 100%;
  table-layout: fixed;
}

.modalTable td,
.modalTable th {
  padding: 8px;
}

.modalTable th {
  font-size: 1.5rem;
  font-weight: 700;
}

.modalTable tr > th:first-child {
  padding-left: 0;
}

.modalTable tr > td:first-child {
  padding-left: 0;
}

.modalTableContainer {
  padding: 0 calc(5 * var(--base-spacing));
  max-height: 415px;
  overflow-y: auto;
}

.addButtonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: calc(4.33 * var(--base-spacing));
}

.addButton {
  font-size: 14px;
  font-weight: 600;
  background-color: #eee;
  border: none;
  border-radius: 50px;
  padding: var(--base-spacing) calc(0.67 * var(--base-spacing));
  cursor: pointer;
}

.modalActionsContainer {
  display: flex;
  align-items: center;
  margin-top: calc(2.5 * var(--base-spacing));
  column-gap: calc(0.67 * var(--base-spacing));
  padding: 0 calc(5 * var(--base-spacing));
}

.delete {
  font-size: 1rem;
  font-weight: 500;
  color: var(--primary-color);
  cursor: pointer;
  text-decoration: none;
  padding-left: calc(0.67 * var(--base-spacing));
}

.modalSave {
  border-radius: 50px;
  color: white;
  background-color: var(--primary-color);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
  border: none;
  padding: var(--base-spacing) calc(2.5 * var(--base-spacing));
  font-size: 1rem;
  cursor: pointer;
}

.modalDiscard {
  border-radius: 50px;
  color: var(--primary-color);
  background-color: transparent;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
  border: solid 1px var(--primary-color);
  padding: var(--base-spacing) calc(2.5 * var(--base-spacing));
  font-size: 1rem;
  cursor: pointer;
}

.modalItemPrice {
  color: #6b6b6b;
  font-weight: 500;
}

.modalInput {
  display: block;
  width: 100%;
  border: none;
  background-color: var(--background-alt);
  padding: calc(1.33 * var(--base-spacing));
  color: black;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.675rem;
}

.modalInput::-webkit-outer-spin-button,
.modalInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modalInput:focus {
  outline: solid 1px black;
}

.modalInput::-webkit-input-placeholder,
.modalInput::-moz-placeholder {
  color: #6b6b6b;
}

.modalInputError {
  outline: none;
  border: solid 1px var(--error);
}

.errorText {
  color: var(--error);
  padding: 0 calc(5 * var(--base-spacing));
}
