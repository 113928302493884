.switch {
  position: relative;
  display: inline-block;
  width: 9rem;
  height: 3rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-color);
  border-radius: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: var(--base-spacing);
  padding-left: var(--base-spacing);
}

.slider:before {
  position: absolute;
  content: "";
  height: 2.375rem;
  width: 2.375rem;
  left: 5px;
  bottom: 5px;
  top: 5px;
  background-color: white;
  transition: 0.5s;
  border-radius: 50%;
}

input:checked + .slider:before {
  transform: translateX(6rem);
}

input:checked + .slider {
  justify-content: flex-start;
}
