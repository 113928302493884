:root {
  --primary-color: #f57153;
  --secondary-color: #737373;
  --background-color: #f9f7f1;
  --base-spacing: 12px;
  --accent-background: #e1e1e1;
  --accent: #707070;
  --border-gray: #e0e0e0;
  --success: #66b253;
  --error: #ed0000;
  --info: #ffa726;
  --background-alt: #eeeeee;
  --table-background: #efefef;
}

* {
  box-sizing: border-box;
  font-family: "filson-pro", sans-serif;
}

body,
html {
  padding: 0;
  margin: 0;
  background-color: var(--background-color);
}

.page-heading {
  font-size: 3.125rem;
  color: black;
  font-weight: 500;
}

.active {
  font-weight: 500;
  background-color: rgba(245, 113, 83, 0.2);
}
