.listContainer {
  padding: calc(2.66 * var(--base-spacing)) calc(5 * var(--base-spacing));
  box-shadow: 10px 10px 19px 0 rgba(0, 0, 0, 0.19);
  border-radius: calc(1.66 * var(--base-spacing));
  margin-top: calc(1.125 * var(--base-spacing));
  width: 100%;
}

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.tableBody {
  height: calc(100vh - 420px);
  overflow-y: auto;
  width: 100%;
  scrollbar-color: var(--primary-color);
  scrollbar-width: 8px;
}

.tableBody::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 16px;
}

.tableBody::-webkit-scrollbar {
  background: transparent;
  width: 8px;
}

.tableBody table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.table th {
  color: var(--accent);
  background-color: var(--table-background);
  padding-top: calc(2 * var(--base-spacing));
  padding-bottom: calc(2 * var(--base-spacing));
  font-size: 1.875rem;
  position: sticky;
  top: 0;
  font-weight: 500;
}

.tableBody td {
  padding-top: calc(1.33 * var(--base-spacing));
  padding-bottom: calc(1.33 * var(--base-spacing));
  font-size: 1.5rem;
}

.tableBody tr {
  cursor: pointer;
}

.table th:first-child {
  padding-left: calc(2 * var(--base-spacing));
}

.table th:last-child {
  padding-right: calc(2 * var(--base-spacing));
}

.table th:first-child {
  border-top-left-radius: calc(1.66 * var(--base-spacing));
  border-bottom-left-radius: calc(1.66 * var(--base-spacing));
}

.table th:last-child {
  border-top-right-radius: calc(1.66 * var(--base-spacing));
  border-bottom-right-radius: calc(1.66 * var(--base-spacing));
}

.tableBody tr td:first-child {
  padding-left: calc(2 * var(--base-spacing));
}

.tableBody tr td:last-child {
  padding-right: calc(2 * var(--base-spacing));
}

.tableBody tr td:first-child {
  border-top-left-radius: calc(1.66 * var(--base-spacing));
  border-bottom-left-radius: calc(1.66 * var(--base-spacing));
}

.tableBody tr td:last-child {
  border-top-right-radius: calc(1.66 * var(--base-spacing));
  border-bottom-right-radius: calc(1.66 * var(--base-spacing));
}

.tableBody tr:nth-child(even) td {
  background-color: var(--table-background);
}

.paginationContainer {
  text-align: end;
}

.paginationContainer:has(*) {
  padding-top: calc(2 * var(--base-spacing));
}

.pageOption {
  display: inline-block;
  margin-left: var(--base-spacing);
  text-decoration: none;
  background-color: var(--table-background);
  color: var(--accent);
  padding: calc(0.67 * var(--base-spacing));
  cursor: pointer;
  vertical-align: middle;
}

.pageNumber {
  padding: calc(0.67 * var(--base-spacing));
  margin-left: var(--base-spacing);
  display: inline-block;
  font-size: 1.25rem;
  color: var(--accent);
}

.dropRow {
  opacity: 0.2;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.contextMenu {
  background-color: var(--accent-background);
  position: absolute;
  color: "black";
  padding: var(--base-spacing);
  border-radius: 8px;
}

.contextMenu > a {
  color: inherit;
  text-decoration: none;
}
