.container {
  position: relative;
}

.list {
  position: absolute;
  top: 138px;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 10;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 10px 10px 19px 0 rgba(0, 0, 0, 0.19);
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.list:focus-within {
  visibility: visible;
  opacity: 1;
}

.list li {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-bottom: solid 1px var(--border-gray);
  padding: 0;
}

.list > li:last-child {
  border-bottom: "none";
}

.list li label {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  padding: var(--base-spacing) calc(2.83 * var(--base-spacing));
}

.name {
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.address {
  font-size: 0.75rem;
  color: var(--accent);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.list::-webkit-scrollbar {
  width: 6px;
}
.list::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 25px;
}

.list::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 25px;
}

.list li:hover,
.list input:checked ~ label {
  background-color: var(--accent-background);
}

.list input[type="radio"] {
  position: absolute;
  left: calc(4 * var(--base-spacing));
  opacity: 0;
  visibility: visible;
}

.container.active .list {
  opacity: 1;
  visibility: visible;
}
