.search {
  padding: calc(1.75 * var(--base-spacing)) var(--base-spacing);
  background-color: var(--accent-background);
  color: var(--accent);
  border: none;
  outline: none;
  border-radius: 8px;
  min-width: 300px;
  font-size: 1rem;
}

.search:focus {
  outline: none;
  border: none;
}

.helper {
  font-size: 0.75rem;
  position: absolute;
  left: var(--base-spacing);
  bottom: 0;
  color: var(--secondary-color);
}

.container {
  position: relative;
}
