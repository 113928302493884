.sidebar {
  width: 21%;
  padding-top: calc(3 * var(--base-spacing));
  max-height: 100%;
  overflow-y: auto;
}

.link {
  display: flex;
  align-items: center;
  padding: var(--base-spacing) calc(2.5 * var(--base-spacing));
  text-decoration: none;
}

.linkText {
  margin-left: calc(2 * var(--base-spacing));
  font-size: 1.25rem;
  color: black;
  font-weight: 300;
}

.inactive {
  opacity: 0.4;
}
