.topSection {
  display: grid;
  row-gap: calc(2 * var(--base-spacing));
  column-gap: calc(2.5 * var(--base-spacing));
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-top: calc(6 * var(--base-spacing));
}

.tableContainer {
  margin-top: calc(1.75 * var(--base-spacing));
}

.loadingContainer {
  display: flex;
  justify-content: center;
  margin-block: 1rem;
}

.bottomSection {
  display: flex;
  justify-content: flex-end;
  margin-top: calc(8.5 * var(--base-spacing));
  margin-bottom: calc(2.5 * var(--base-spacing));
}

.pricingContainer {
  background-color: var(--accent-background);
  padding: calc(2.5 * var(--base-spacing)) calc(2 * var(--base-spacing));
  align-self: flex-start;
}

.pricingContainer > div {
  display: flex;
  align-items: center;
  gap: calc(12 * var(--base-spacing));
  justify-content: space-between;
  font-size: 1.875rem;
  color: var(--accent);
}

.price {
  text-align: right;
  color: black;
}

.actionContainer {
  display: flex;
  margin-top: calc(2.5 * var(--base-spacing));
  justify-content: flex-end;
  margin-bottom: calc(5 * var(--base-spacing));
}
