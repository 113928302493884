.container {
  padding-left: calc(2 * var(--base-spacing));
}

.heading {
  font-size: 2.8125rem;
}

.headingContainer {
  margin-bottom: calc(4.5 * var(--base-spacing));
  margin-top: calc(1.5 * var(--base-spacing));
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: var(--base-spacing);
}
