.container {
  padding-left: calc(2 * var(--base-spacing));
}

.heading {
  font-size: 2.8125rem;
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(4.5 * var(--base-spacing));
  margin-top: calc(1.5 * var(--base-spacing));
}

.branchesHeading {
  margin-top: calc(3.25 * var(--base-spacing));
  margin-bottom: calc(3.25 * var(--base-spacing));
  font-weight: 500;
  font-size: 2.75rem;
}

.branchRow {
  display: flex;
  column-gap: calc(2.5 * var(--base-spacing));
  align-items: center;
}

.branchChargesContainer {
  margin-bottom: calc(2 * var(--base-spacing));
  display: flex;
  column-gap: calc(2.5 * var(--base-spacing));
  align-items: center;
  margin-top: var(--base-spacing);
}

.daysLabel {
  color: var(--accent);
  font-size: 1.25rem;
  font-weight: 500;
}

.branchDelete {
  cursor: pointer;
}

.actionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(2 * var(--base-spacing));
}

.mapContainer {
  height: 500px;
  width: 100%;
  margin-bottom: calc(2 * var(--base-spacing));
}

.branchButtonContainer {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.mapDeleteContainer {
  padding-right: 8px;
}

.mapDelete {
  background-color: white;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
}
