.container {
  margin-top: calc(3 * var(--base-spacing));
}

.row {
  display: grid;
  column-gap: calc(2.5 * var(--base-spacing));
  row-gap: calc(2 * var(--base-spacing));
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.column > div:first-child {
  margin-bottom: calc(2 * var(--base-spacing));
}

.summary {
  background-color: var(--accent-background);
  padding: calc(0.5 * var(--base-spacing)) calc(2 * var(--base-spacing));
  border-radius: 10px;
  min-height: 138px;
}

.summaryHeader {
  font-weight: 500;
  font-size: 1.5rem;
  color: var(--accent);
}

.suppliersHeader {
  margin-top: var(--base-spacing);
}

.supplierOrderRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(0.75 * var(--base-spacing));
}

.supplierOrderRow:last-child {
  margin-bottom: 0;
}

.poButton {
  font-size: 0.75rem;
  background-color: var(--primary-color);
  color: white;
  padding: calc(0.25 * var(--base-spacing)) calc(1.75 * var(--base-spacing));
  border-radius: 30px;
  border: none;
  cursor: pointer;
}

.poCreatedButton {
  background-color: var(--success);
  padding-left: calc(0.5 * var(--base-spacing));
  padding-right: calc(0.5 * var(--base-spacing));
  text-decoration: none;
}

.poButton:disabled {
  opacity: 0.5;
}

.tableContainer {
  margin-top: calc(1.75 * var(--base-spacing));
}

.multiRowContainer {
  display: flex;
  flex-direction: column;
  gap: calc(2.5 * var(--base-spacing));
}

.success {
  color: var(--success);
}

.error {
  color: var(--error);
}

.info {
  color: var(--info);
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  margin-top: calc(8.5 * var(--base-spacing));
  margin-bottom: calc(2.5 * var(--base-spacing));
  column-gap: calc(6 * var(--base-spacing));
}

.pricingContainer {
  background-color: var(--accent-background);
  padding: calc(2.5 * var(--base-spacing)) calc(2 * var(--base-spacing));
  align-self: flex-start;
}

.pricingContainer > div {
  display: flex;
  align-items: center;
  gap: calc(12 * var(--base-spacing));
  justify-content: space-between;
  font-size: 1.875rem;
  color: var(--accent);
  margin-bottom: calc(3.33 * var(--base-spacing));
}

.pricingContainer > div:nth-child(3) {
  margin-bottom: 0;
  margin-top: var(--base-spacing);
  font-size: 2.5rem;
}

.price {
  text-align: right;
  color: black;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: calc(2.75 * var(--base-spacing));
  margin-bottom: calc(2 * var(--base-spacing));
}

.buttonsContainer:first-child {
  color: var(--error);
  font-size: 1.25rem;
}

.modalTopRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalHeading {
  font-size: 1.875rem;
  color: var(--primary-color);
  font-weight: 500;
}

.modalActionContainer {
  display: flex;
  justify-content: flex-end;
  margin: calc(3 * var(--base-spacing)) 0;
}

.modalContainer {
  width: 560px;
}

.dateContainer {
  margin: calc(3 * var(--base-spacing)) 0;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  margin-block: 1rem;
}
