.container {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.25rem;
  background-color: var(--background-alt);
  border-radius: 20px;
}

.value {
  flex-grow: 1;
  background-color: white;
  color: var(--primary-color);
  margin: 4px 0;
  text-align: center;
  line-height: 1.25rem;
  padding: 6px 0;
}

.iconContainer {
  cursor: pointer;
  padding: 10px calc(1.33 * var(--base-spacing));
  display: inline-block;
  height: 40px;
}

.iconContainer > svg {
  vertical-align: top;
}

.leftIcon {
  padding-top: 18px;
}
