.container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.childrenContainer {
  background-color: white;
  padding: calc(2 * var(--base-spacing));
  border-radius: 8px;
  box-shadow: 10px 10px 19px 0 rgba(0, 0, 0, 0.19);
}
