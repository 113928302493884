.container {
  padding-left: calc(2 * var(--base-spacing));
}

.heading {
  font-size: 2.8125rem;
}

.headingContainer {
  margin-bottom: calc(4.5 * var(--base-spacing));
  margin-top: calc(1.5 * var(--base-spacing));
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form {
  display: flex;
  column-gap: calc(2 * var(--base-spacing));
}

.imageContainer {
  width: 282px;
  height: 282px;
  border-radius: 12px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.buttonContainer {
  margin-top: calc(2 * var(--base-spacing));
}

.modalTopRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--base-spacing);
}

.modalHeading {
  font-size: 1.875rem;
  color: var(--primary-color);
  font-weight: 500;
}

.modalSave {
  border-radius: 50px;
  color: white;
  background-color: var(--primary-color);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
  border: none;
  padding: var(--base-spacing) calc(2.5 * var(--base-spacing));
  font-size: 1rem;
  cursor: pointer;
}

.canvas {
  width: 390px;
  height: 530px;
}

.modalActionsContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: calc(2.5 * var(--base-spacing));
}

.submitContainer {
  flex-grow: 1;
  align-self: flex-end;
  text-align: right;
}

.spacer {
  margin-top: var(--base-spacing);
}
